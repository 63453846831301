@import 'vars';
@import 'mixins';

/*
	Strata by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Basic */

	body, input, select, textarea {
		font-size: 12pt;
	}
